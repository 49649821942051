<template>
  <section class="section" :class="props.class">
    <h4 v-if="props.data?.title">{{ props.data.title }}</h4>
    <div v-if="props.data?.description" v-html="props.data.description"></div>
    <slot></slot>
  </section>
</template>

<script setup lang="ts">
  interface Props {
    data?: {
      title?: string
      description?: string
    }
    class?: string
  }

  const props = defineProps<Props>()
</script>

<style scoped lang="scss">
  .section {
    width: 100%;
    padding: var(--space);
    border-radius: var(--radius);
    background-color: var(--bg-preset-color-20);
    h4 {
      margin: 0 0 var(--space) 0;
    }
  }

  .section.cards-wrap {
    h4 {
      padding-left: var(--space);
    }
  }
</style>
