<template>
  <Main>
    <div class="turbo">
      <ProfileInfo :img="setup.data.photo" :title="setup.data.title" />
      <p> {{ setup.locale.info.text }} </p>
      <div class="list">
        <ul>
          <li v-for="item in setup.locale.info.lists">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="actions">
        <form @submit.prevent="submitFormData">
          <label v-for="(item) in setup.data.prices">
            <input type="radio" :value="item.invoice" name="invoice">
            {{ item.text }}
          </label>
          <Button>{{ setup.base_locale.buy }}</Button>
        </form>
        <ButtonCloseApp>{{ setup.base_locale.back }}</ButtonCloseApp>
      </div>
    </div>
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'

  const { setInitControllerData, setServiceData } = useControllerStore()
  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    controller: string;
  }>()

  const submitFormData = (event: Event) => {
    const formData = new FormData(event.target as HTMLFormElement);
    const data = Object.fromEntries(formData.entries());

    if (formData.has('invoice')) {
      setServiceData(data, '')
    }
  }

  setInitControllerData(props);
</script>

<style scoped lang="scss">
.turbo {
  .list {
  display: flex;
  justify-content: center;
    ul {
      padding: 0;
      list-style: none;
      line-height: 2rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-half);
    width: 100%;
    margin: 0 var(--space) var(--space);

    label {
      max-width: 280px;
      width: 100%;
      padding: var(--space-half);
      border-radius: var(--radius-half);
      border: 4px solid var(--grey);
      text-align: center;
      &:has(input:checked) {
        border: 4px solid var(--danger-color);
      }
    }

    input {
      display: none
    }

    button {
      margin-top: var(--space-half);
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space);
    margin-top: var(--space);
  }
}
</style>
