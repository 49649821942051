<template>
  <Main>
    <form @submit.prevent="submitFormData" class="payments">
      <h1>{{ setup.base_locale.h1 }}</h1>
      <ProfileInfo v-if="setup.data.group.photo"
        :img="setup.data.group.photo"
        :title="setup.data.group.title"
      />
      <Section :data="setup.locale" />
      <Section v-if="setup.locale.payment_info" :data="setup.locale.payment_info" />
      <Button v-if="setup.locale.prime">{{ setup.locale.prime }}</Button>
      <Section :data="setup.base_locale" :class="'cards-wrap'">
        <div class="cards">
          <label class="card" v-for="(method, index) in setup.locale.methods">
            <svg class="icon"><use :xlink:href="`${icons}#${method.icon}`"></use></svg>
            <h5>{{ method.title }}</h5>
            <input type="radio" name="payment"
              :id="method.id"
              :value="method.id"
              :checked="index === 0"
              @change="changePayment"
            >
          </label>
        </div>
      </Section>
      <Section v-if="setup.locale.input" :data="setup.locale.input">
        <Input type="number" name="amount" :placeholder :min required />
      </Section>
      <Section v-if="setup.locale.comment">
        <h4>{{ setup.locale.comment }}</h4>
        <Input name="comment" />
      </Section>
      <div v-if="setup.locale.details">{{ setup.locale.details }}</div>
      <Section v-if="setup.locale.anonymous" :class="'switch'">
        <h4>{{ setup.locale.anonymous }}</h4>
        <Switch :name="'anonymous'" />
      </Section>
      <Button>{{ setup.locale.submit }}</Button>
    </form>
  </Main>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import { useControllerStore } from '@/stores/controller'
  import icons from '@/assets/icons/icons.symbol.svg'

  const { setInitControllerData, setServiceData } = useControllerStore()
  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    controller: string;
  }>()

  const min = ref(0);
  const placeholder = ref('')

  watch(() => setup.value.locale, (newLocale) => {
    if (newLocale.input) {
      min.value = newLocale.input.coins.min
      placeholder.value = newLocale.input.coins.placeholder;
    }
  }, { immediate: false });

  const changePayment = ({ target }: { target: HTMLInputElement }) => {
    min.value = setup.value.locale.input[target.id].min.toString()
    placeholder.value = setup.value.locale.input[target.id].placeholder
  }

  const submitFormData = (event: Event) => {
    const formData = new FormData(event.target as HTMLFormElement);
    const data = Object.fromEntries(formData.entries());

    if (formData.has('amount')) {
      setServiceData(data, '')
    }
  }

  setInitControllerData(props);
</script>

<style scoped lang="scss">
  .payments {
    display: flex;
    flex-direction: column;
    gap: var(--space);
    h1 {
      margin: 0;
    }

    h4 {
      margin: 0 0 var(--space) 0;
    }

    .group {
      display: flex;
      gap: var(--space);
    }

    .section.switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        margin: 0;
      }
    }

    .cards-wrap {
      padding-left: 0;
      padding-right: 0;
    }

    .cards {
      display: flex;
      gap: var(--space);
      padding-right: var(--space);
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: var(--radius-half);
        background-color: var(--bg-preset-color-30);
        flex: 1;
        height: 120px;
        min-width: 180px;
        padding: var(--space);
        border: 2px solid transparent;
        cursor: pointer;
        &:first-child {
          margin-left: var(--space);
        }
        &:last-child {
          margin-right: var(--space);
        }
        .icon {
          width: 30px;
          height: 30px;
        }
        h5 {
          display: block;
          font-size: 18px;
          margin: auto 0 0;
        }
        input {
          display: none;
        }
        &:has(input:checked) {
          border-color: var(--primary-color);
        }
      }
    }

    button {
      max-width: 100%;
    }
  }
</style>
