<template>
  <Link class="center" method="post" as="button"
    :href="props.href"
    :data
  >
    <slot></slot>
  </Link>
</template>

<script setup lang="ts">
  import { Link } from '@inertiajs/vue3'

  interface Props {
    href: string
    data: {
      type: string
    }
  }

  const props = defineProps<Props>()
</script>

<style scoped lang="scss">
  button {
    display: block;
    width: 100%;
    max-width: 280px;
    padding: var(--btn-padding);
    border: 0px;
    align-self: center;
    border-radius: var(--radius-half);
    background-color: var(--btn-primary-color);
    font-family: 'DMSans', sans-serif;
    color: var(--btn-text-color);
    font-size: var(--btn-text-size);
    text-align: center;
  }
</style>
