<template>
  <input :type :min :max :placeholder :required
    @input="validator" v-model="message"
  >
</template>

<script setup lang="ts">
  interface Props {
    type?: string
    placeholder?: string
    min?: string
    max?: string
    required?: boolean
  }

  const message = ref('')

  const {
    type = 'text',
    min = '',
    max = '99999999',
    placeholder = '',
    required,
  } = defineProps<Props>()

  const validator = ({ target }: Event) => {
    const input = (target as HTMLInputElement)

    if (type === 'number' && input.value == '') {
      message.value = ' ';
    }
  }
</script>

<style scoped lang="scss">
  input {
    width: 100%;
    height: 50px;
    padding: 0 var(--space-half);
    font-size: 18px;
    border: 2px solid var(--bg-preset-color-60);
    border-radius: var(--radius-half);
    background-color: var(--bg-preset-color-30);
    outline: none;
    color: var(--input-text-color);
    transition: all .3s ease;
    &:focus, &:hover {
      border-color: var(--bg-preset-color-70);
    }

    &[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &::placeholder {
      color: var(--bg-preset-color-70);
    }

    &:focus:not(:valid) {
      border-color: red;
    }
  }
</style>
