import { storeToRefs } from 'pinia'
import { useControllerStore } from '@/stores/controller'

export const watchScrollTo = () => {
    const { setup } = storeToRefs(useControllerStore())

    watch(() => setup.value.data, (newData) => {
        if (!newData.scrollTo) return;
        setTimeout(() =>{
            const element = document.querySelector(`${newData.scrollTo.selector}`)

            element.classList.add('active')
            element.scrollIntoView({ behavior: 'smooth' })
        }, 0)
    }, { immediate: false });
}
