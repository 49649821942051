<template>
  <div class="confirm">
    <Logo />
    <h1>{{ setup.locale.message.header }}</h1>
    <Section v-html="setup.locale.message.content" :class="'content'" />
    <Section v-if="setup.locale.message.comment_block">
      <h4>{{ setup.locale.message.comment_title }}</h4>
      {{ setup.locale.message.comment_block }}
    </Section>
    <div>{{ setup.locale.message.warning }}</div>
    <Button @click.prevent="submitData">{{ setup.locale.message.confirm }}</Button>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  const { setServiceData } = useControllerStore()

  const { setup } = storeToRefs(useControllerStore())

  const submitData = () => {
    setServiceData(setup.value.locale.data, setup.value.locale.data.type)
  }
</script>

<style scoped lang="scss">
  .confirm {
    display: flex;
    flex-direction: column;
    gap: var(--space);
    img {
      margin: 0 auto;
    }
    h1 {
      margin-bottom: 0;
      font-size: 1.5rem;
      text-align: center;
    }
    h4 {
      margin-top: 0;
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: var(--space-half);
    }
  }
</style>
